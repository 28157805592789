import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from "sweetalert2";
import RichTextEditor from 'html-verify-react-rte';
import {validateEmail} from '../utils/HelpfulFunction';
const google = window.google;
let geocoder = new google.maps.Geocoder();
const isValidDomain = require('is-valid-domain');

class SetUpLoginVariables extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    componentDidMount(){
        this.tenantVariablesRef = base.syncState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then(){
                const tenantVariables = this.state.tenantVariables;
                let advanced = false;
                if(tenantVariables.doNotCollectEmail || tenantVariables.sanitizeEmails || tenantVariables.allowList || tenantVariables.blockList || tenantVariables.collectDistance || tenantVariables.noMandatoryTermsAndConditions){
                    advanced = true;
                }
                if(tenantVariables){
                    let notAcceptableLocationMessage = tenantVariables.notAcceptableLocationMessage;
                    let promotionTextTwo = tenantVariables.promotionTextTwo;
                    let promotionText = tenantVariables.promotionText;
                    if(!notAcceptableLocationMessage){
                        notAcceptableLocationMessage = RichTextEditor.createEmptyValue();
                    } else {
                        notAcceptableLocationMessage = RichTextEditor.createValueFromString(notAcceptableLocationMessage, 'html');
                    }
                    if(!promotionText){
                        promotionText = RichTextEditor.createEmptyValue();
                    } else {
                        promotionText = RichTextEditor.createValueFromString(promotionText, 'html');
                    }
                    if(!promotionTextTwo){
                        promotionTextTwo = RichTextEditor.createEmptyValue();
                    } else {
                        promotionTextTwo = RichTextEditor.createValueFromString(promotionTextTwo, 'html');
                    }
                    this.setState({
                        acceptableDistance: tenantVariables.acceptableDistance,
                        locationPermissionsBody: tenantVariables.locationPermissionsBody,
                        locationPermissionsHeader: tenantVariables.locationPermissionsHeader,
                        formattedAddress: tenantVariables.formattedAddress,
                        notAcceptableLocationMessage: notAcceptableLocationMessage,
                        notAcceptableLocationHeader: tenantVariables.notAcceptableLocationHeader,
                        canPlayOutside: tenantVariables.canPlayOutside,
                        allowList: tenantVariables.allowList,
                        blockList: tenantVariables.blockList,
                        promotionText: promotionText,
                        promotionTextTwo: promotionTextTwo,
                        advanced: advanced
                    })
                }
            }
        });
        this.setState({
            loading:false
        })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleTextChange(evt){
        const tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[evt.target.name] = evt.target.value
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    selectOption(button){
      const tenantVariablesCopy = this.state.tenantVariables;
      tenantVariablesCopy[button] = !tenantVariablesCopy[button]
      this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    updateDistance(){
        const tenantVariables = this.state.tenantVariables;
        if(!this.state.acceptableDistance || !this.state.formattedAddress){
            swal({
                title: 'Hold on!',
                text: "Please make sure all the form fields are filled out",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(this.state.acceptableDistance <= 0){
            swal({
                title: 'Hold on!',
                text: "Please make sure distance is greater than 0",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        this.setState({loading:true})
        const vm = this;
        geocoder.geocode({'address':this.state.formattedAddress}, function(results, status) {
            if (status === 'OK') {
                if(results.length === 1){
                    const tenantVariablesCopy = tenantVariables;
                    tenantVariablesCopy["formattedAddress"] = vm.state.formattedAddress;
                    tenantVariablesCopy["acceptableDistance"] = vm.state.acceptableDistance;
                    tenantVariablesCopy["longitude"] = results[0].geometry.location.lng();
                    tenantVariablesCopy["latitude"] = results[0].geometry.location.lat();
                    tenantVariablesCopy["locationPermissionsBody"] = vm.state.locationPermissionsBody || "";
                    tenantVariablesCopy["locationPermissionsHeader"] = vm.state.locationPermissionsHeader || "";
                    vm.setState({
                        tenantVariables:  tenantVariablesCopy,
                        loading: false
                    });
                    swal({
                        title: 'Updates Successful',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                } else {
                    vm.setState({loading:false})
                    swal({
                        title: 'Hold on!',
                        text: "Too many locations have that address! Add more detail to get only 1 address",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            } else {
                vm.setState({loading:false})
                swal({
                    title: 'Hold on!',
                    text: 'Finding address was not successful because ' + status,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    updatePromotionText(variable){
        const tenantVariablesCopy = this.state.tenantVariables || {};
        let promotionText = this.state[variable].toString('html');
        if(promotionText === "<p><br></p>"){
            promotionText = "";
        }
        tenantVariablesCopy[variable] = promotionText;
        this.setState({
            tenantVariables:  tenantVariablesCopy,
            loading: false
        });
        swal({
            title: 'Updates Successful',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    updateList(listToUpdate){
        const listName = listToUpdate + 'List';
        let listToSave = this.state[listName];
        listToSave = listToSave.trim().toLowerCase();
        let rejoinedCleanedDomainsEmails = "";
        if(listToSave){
            const splitList = listToSave.split(/[ ,\s]+/);
            for(const splitListIndex in splitList) {
                const splitItem = splitList[splitListIndex];
                let valid;
                if (splitItem.indexOf('@') === -1) {
                    valid = isValidDomain(splitItem);
                } else {
                    valid = validateEmail(splitItem);
                }
                if (!valid) {
                    swal({
                        title: 'Hold on!',
                        text: "One of the items on the block list isn't a valid email or domain. The item causing an issue is: " + splitItem + ".",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return
                }
            }
            rejoinedCleanedDomainsEmails = splitList.join(" ");
        }
        base.update(`tenantVariables`, {
            data: {[listName]: rejoinedCleanedDomainsEmails},
            then(err){
                if(err){
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    })
                    console.log(err)
                } else {
                    swal({
                        title: 'List Updated',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                }
            }
        })
    }

    handleRichTextChange = (name, text) => {
        this.setState({
            [name]: text
        })
    }

    returnCorrectRichTextObject(text){
        if(!text){
            return RichTextEditor.createEmptyValue();
        }
        if(typeof text === 'string') {
            text = RichTextEditor.createValueFromString(text, 'html')
        }
        return text;
    }

    readyRichTextForSubmit(text){
        text = text || RichTextEditor.createEmptyValue();
        text = text.toString('html');
        if(text === "<p><br></p>"){
            text = "";
        }
        return text
    }

    handleAddOptionSubmit(event){
        event.preventDefault();
        const tenantVariablesCopy = this.state.tenantVariables || {};
        const howYouHeardOptions = tenantVariablesCopy.howYouHeardOptions || [];
        howYouHeardOptions.push(event.target.optionLabel.value);
        tenantVariablesCopy['howYouHeardOptions'] = howYouHeardOptions;
        this.setState({ tenantVariables:  tenantVariablesCopy});
        event.target.reset();
    };

    removeOption(data){
        const tenantVariablesCopy = this.state.tenantVariables || {};
        let howYouHeardOptions = tenantVariablesCopy.howYouHeardOptions || [];
        for(const i in howYouHeardOptions){
            let value = howYouHeardOptions[i].label || howYouHeardOptions[i];
            if(value && data && value === data){
                howYouHeardOptions.splice(parseInt(i), 1);
            }
        }
        tenantVariablesCopy['howYouHeardOptions'] = howYouHeardOptions;
        this.setState({
            tenantVariables: tenantVariablesCopy
        })
    }

    render() {
        const variables = this.state.tenantVariables || {}
        const collectName = variables.collectName;
        const nameInEmail = variables.nameInEmail;
        const noLastNameCheck = variables.noLastNameCheck;
        const noMandatoryTermsAndConditions = variables.noMandatoryTermsAndConditions;
        const collectZipCode = variables.collectZipCode;
        const collectOptIn = variables.collectOptIn;
        const collectOptInTwo = variables.collectOptInTwo;
        const notDefaultCheckedOptIn = variables.notDefaultCheckedOptIn;
        const collectOptInTwoNotDefaultCheck = variables.collectOptInTwoNotDefaultCheck;
        const promotionText = this.state.promotionText || RichTextEditor.createEmptyValue();
        const promotionTextTwo = this.state.promotionTextTwo || RichTextEditor.createEmptyValue();
        const collectBirthday = variables.collectBirthday;
        const collectPhoneNumber = variables.collectPhoneNumber;
        const formBirthday = variables.formBirthday;
        const optionalPhoneNumber = variables.optionalPhoneNumber;
        const allowAnonymousLogin = variables.allowAnonymousLogin;
        const doNotCollectEmail = variables.doNotCollectEmail;
        const collectDistance = variables.collectDistance;
        const collectFullAddress = variables.collectFullAddress;
        const collectHowYouHeard = variables.collectHowYouHeard;
        const howYouHeardOptions = variables.howYouHeardOptions || [];
        const acceptableDistance = parseFloat(this.state.acceptableDistance);
        const doNotVerifyAmericanZipCode = variables.doNotVerifyAmericanZipCode;
        const verifyCanadianZipCode = variables.verifyCanadianZipCode;
        let formattedAddress = this.state.formattedAddress;
        let locationPermissionsBody = this.state.locationPermissionsBody;
        let locationPermissionsHeader = this.state.locationPermissionsHeader;
        const sanitizeEmails = variables.sanitizeEmails;
        const canPlayOutside = this.state.canPlayOutside;
        const notAcceptableLocationMessage = this.state.notAcceptableLocationMessage || RichTextEditor.createEmptyValue();
        const notAcceptableLocationHeader = this.state.notAcceptableLocationHeader;
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out container-left-rules"  style={{width: "50%", float:"left"}}>
                <div className="admin-form-box">
                    <div className="form-group">
                      <label htmlFor="rulesAndRegsText">Collect Name Of Fan</label>
                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY name field to the login flow</p>
                      <input type="checkbox" checked={collectName} id="collectName" name="collectName" onClick={()=> this.selectOption("collectName")}/>
                    </div>
                    {collectName &&
                        <>
                            <div className="form-group">
                                <label htmlFor="nameInEmail">Include Name In Email</label>
                                <br/>
                                <input type="checkbox" checked={nameInEmail} id="nameInEmail" name="nameInEmail" onClick={()=> this.selectOption("nameInEmail")}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="noLastNameCheck">Turn Off Last Name Check</label>
                                <br/>
                                <input type="checkbox" checked={noLastNameCheck} id="noLastNameCheck" name="noLastNameCheck" onClick={()=> this.selectOption("noLastNameCheck")}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="namePlaceHolderText">Name Placeholder Text</label>
                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Use this to inform the fans which name to enter</p>
                                <input value={variables.namePlaceHolderText} className="form-control" type="text" id="namePlaceHolderText" name="namePlaceHolderText" placeholder="First & Last Name" onChange={this.handleTextChange}/>
                            </div>
                        </>
                    }
                    <div className="form-group">
                      <label htmlFor="collectOptIn">Collect ZipCode</label>
                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY zip code field to the login flow</p>
                      <input type="checkbox" checked={collectZipCode} id="collectZipCode" name="collectZipCode" onClick={()=> this.selectOption("collectZipCode")}/>
                    </div>
                    {collectZipCode &&
                        <div className="form-group">
                            <label htmlFor="zipCodePlaceholder">Zipcode Placeholder Text</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Use this to inform the fans which zip to enter</p>
                            <input value={variables.zipCodePlaceholder} className="form-control" type="text" id="zipCodePlaceholder" name="zipCodePlaceholder" placeholder="Zipcode" onChange={this.handleTextChange}/>
                        </div>
                    }
                    <div className="form-group">
                        <label htmlFor="collectFullAddress">Collect Full Address</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY address field to the login flow</p>
                        <input type="checkbox" checked={collectFullAddress} id="collectFullAddress" name="collectFullAddress" onClick={()=> this.selectOption("collectFullAddress")}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="collectBirthday">Collect Birthday</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY birthday input field</p>
                        <input type="checkbox" checked={collectBirthday} id="collectBirthday" name="collectBirthday" onClick={()=> this.selectOption("collectBirthday")}/>
                        <div style={{display:collectBirthday ? "":"none", margin: 5}}>
                            <div className="form-group" style={{display: collectBirthday? "":"none"}}>
                                <label htmlFor="allowedAge">Age</label>
                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter an age that the user must be above to play</p>
                                <input type="number" id="allowedAge" name="allowedAge" className="form-control" value={variables.allowedAge} onChange={this.handleTextChange}/>
                            </div>
                            <label className="radio-inline" style={{marginRight:5}}><input type="radio" name="formBirthday" id="formBirthday" onChange={()=>this.selectOption("formBirthday")} checked={formBirthday}/> In Form Birthday Input</label>
                            <label className="radio-inline"><input type="radio" name="formBirthday" id="formBirthday" onChange={()=>this.selectOption("formBirthday")} checked={!formBirthday}/> Page Birthday Input</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="collectPhoneNumber">Collect Phone Number</label>
                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this
                            box
                            to add a MANDATORY phone number field to the login flow</p>
                        <input type="checkbox" checked={collectPhoneNumber} id="collectPhoneNumber"
                               name="collectPhoneNumber"
                               onClick={() => this.selectOption("collectPhoneNumber")}/>
                        {collectPhoneNumber &&
                            <div>
                                <label className="radio-inline" style={{marginRight:5}}><input type="radio" name="optionalPhoneNumber" id="optionalPhoneNumber" onChange={()=>this.selectOption("optionalPhoneNumber")} checked={optionalPhoneNumber}/> Optional</label>
                                <label className="radio-inline"><input type="radio" name="optionalPhoneNumber" id="optionalPhoneNumber" onChange={()=>this.selectOption("optionalPhoneNumber")} checked={!optionalPhoneNumber}/> Mandatory</label>
                            </div>
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="collectOptIn">Turn on Optional Opt-In</label>
                        <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                        <input type="checkbox" checked={collectOptIn} id="collectOptIn" name="collectOptIn" onClick={() => this.selectOption("collectOptIn")}/>
                    </div>
                    {collectOptIn &&
                        <>
                            <div className="form-group">
                                <label htmlFor="notDefaultCheckedOptIn">Turn Off Default Checked Opt-In</label>
                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This will default the opt-in check box to be UNCHECKED</p>
                                <input type="checkbox" checked={notDefaultCheckedOptIn} id="notDefaultCheckedOptIn" name="notDefaultCheckedOptIn" onClick={()=> this.selectOption("notDefaultCheckedOptIn")}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="promotionText">Opt-In Text</label>
                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Text That Shows Next To Opt-In Check Box</p>
                                <RichTextEditor toolbarConfig={toolbarConfig} id="promotionText" name="promotionText" value={promotionText} onChange={(text)=>this.handleRichTextChange('promotionText', text)} placeholder="Opt in to our sweet offer"/>
                                <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updatePromotionText("promotionText")}><span className="fa fa-arrow-circle-o-up"/> Update Promotion Text</button>
                                {/*<textarea id="promotionText" name="promotionText" className="form-control" value={variables.promotionText} onChange={this.handleTextChange}/>*/}
                            </div>
                            <div className="form-group">
                                <label htmlFor="collectOptInTwo">Collect Second Opt-In</label>
                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                                <input type="checkbox" checked={collectOptInTwo} id="collectOptInTwo" name="collectOptInTwo" onClick={()=> this.selectOption("collectOptInTwo")}/>
                            </div>
                            {collectOptInTwo &&
                                <>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Text That Shows Next To Opt-In Check Box</p>
                                    <RichTextEditor toolbarConfig={toolbarConfig} id="promotionTextTwo" name="promotionTextTwo" value={promotionTextTwo} onChange={(text)=>this.handleRichTextChange('promotionTextTwo', text)} placeholder="Opt in to our sweet offer"/>
                                    <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updatePromotionText("promotionTextTwo")}><span className="fa fa-arrow-circle-o-up"/> Update Promotion Text Two</button>
                                    {/*<textarea className="form-control" id="promotionTextTwo" name="promotionTextTwo" value={variables.promotionTextTwo} onChange={this.handleTextChange}/>*/}
                                    <div className="form-group">
                                        <label htmlFor="collectOptInTwoNotDefaultCheck">Default Not Checked</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to have the Opt-In field not default checked</p>
                                        <input type="checkbox" checked={collectOptInTwoNotDefaultCheck} id="collectOptInTwoNotDefaultCheck" name="collectOptInTwoNotDefaultCheck" onClick={()=> this.selectOption("collectOptInTwoNotDefaultCheck")}/>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {/*<div className="form-group">*/}
                    {/*    <label htmlFor="collectOptIn">Turn on Optional Opt-In</label>*/}
                    {/*    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>*/}
                    {/*    <input type="checkbox" checked={collectOptIn} id="collectOptIn" name="collectOptIn" onClick={()=> this.selectOption("collectOptIn")}/>*/}
                    {/*</div>*/}
                    {/*{collectOptIn &&*/}
                    {/*    <>*/}
                    {/*        <div className="form-group">*/}
                    {/*            <label htmlFor="notDefaultCheckedOptIn">Turn Off Default Checked Opt-In</label>*/}
                    {/*            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This will default the opt-in check box to be UNCHECKED</p>*/}
                    {/*            <input type="checkbox" checked={notDefaultCheckedOptIn} id="notDefaultCheckedOptIn" name="notDefaultCheckedOptIn" onClick={()=> this.selectOption("notDefaultCheckedOptIn")}/>*/}
                    {/*        </div>*/}
                    {/*        <div className="form-group">*/}
                    {/*            <label htmlFor="promotionText">Opt-In Text</label>*/}
                    {/*            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Text That Shows Next To Opt-In Check Box</p>*/}
                    {/*            <textarea id="promotionText" name="promotionText" className="form-control" value={variables.promotionText} onChange={this.handleTextChange}/>*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*}*/}
                    <div className="form-group">
                        <label htmlFor="collectOptIn">How You Heard</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a mandatory how you heard field</p>
                        <input type="checkbox" checked={collectHowYouHeard} id="collectHowYouHeard" name="collectHowYouHeard" onClick={()=> this.selectOption("collectHowYouHeard")}/>
                        {collectHowYouHeard &&
                            <div>
                                <div className="form-group">
                                    <label htmlFor="howDidYouHearAboutUsQuestion">How Did You Hear About Us Question</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter the question you want the fans to answer to figure out where they came from?</p>
                                    <input placeholder="How Did You Hear About This?" type="text" id="howDidYouHearAboutUsQuestion" name="howDidYouHearAboutUsQuestion" className="form-control" value={variables.howDidYouHearAboutUsQuestion} onChange={this.handleTextChange}/>
                                </div>
                                <ol>
                                    {howYouHeardOptions && howYouHeardOptions.length > 0 && (
                                        howYouHeardOptions.map(function(data, i){
                                            return <li>
                                                {data.label || data}, <button className="btn" onClick={()=> this.removeOption(data.label || data)}>🗑️</button>
                                            </li>
                                        }, this)
                                    )}
                                </ol>
                                <form onSubmit={(event) => this.handleAddOptionSubmit(event)}>
                                    <label htmlFor="optionLabel">Option label:</label>
                                    <input className="form-control" id="optionLabel" name="optionLabel" type="text" required />
                                    <br/>
                                    <button className="btn btn-primary btn-lg update-button" type="submit">Add option</button>
                                </form>
                            </div>
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="showAdvancedSettings">Advanced</label>
                        <br/>
                        <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                    </div>
                    {this.state.advanced &&
                    <>
                        <div className="form-group">
                            <label htmlFor="collectDistance">Turn On/Off Geolocation</label>
                            <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add MANDATORY verification of distance from stadium</p>
                            <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={() => this.selectOption("collectDistance")}/>
                            <span style={{display: collectDistance ? 'block' : 'none'}}>
                                <div>
                                        <label htmlFor="formattedAddress" style={{marginTop: 5, marginBottom: 0}}>Address</label>
                                        <br/>
                                        <input className="form-control" type="text" id="formattedAddress" name="formattedAddress" value={formattedAddress} onChange={this.handleChange} placeholder="Address"/>
                                        <br/>
                                        <label htmlFor="locationPermissionsHeader"
                                               style={{marginTop: 5, marginBottom: 0}}>Requesting Location Permissions Header</label>
                                        <br/>
                                        <input className="form-control" type="text" id="locationPermissionsHeader"
                                               name="locationPermissionsHeader" value={locationPermissionsHeader}
                                               onChange={this.handleChange} placeholder="Location Permissions Header"/>
                                        <br/>
                                        <label htmlFor="locationPermissionsBody"
                                               style={{marginTop: 5, marginBottom: 0}}>Requesting Location Permissions Body</label>
                                        <br/>
                                        <textarea className="form-control" id="locationPermissionsBody"
                                                  name="locationPermissionsBody" value={locationPermissionsBody}
                                                  onChange={this.handleChange} placeholder="Location Permissions Body"/>
                                        <br/>
                                        <label htmlFor="notAcceptableLocationHeader" style={{
                                            marginTop: 5,
                                            marginBottom: 0
                                        }}>Not Acceptable Location Header</label>
                                        <br/>
                                        <input className="form-control" type="text" id="notAcceptableLocationHeader"
                                               name="notAcceptableLocationHeader" value={notAcceptableLocationHeader}
                                               onChange={this.handleChange}
                                               placeholder="Not Acceptable Location Header"/>
                                        <br/>
                                        <label htmlFor="notAcceptableLocationMessage" style={{
                                            marginTop: 5,
                                            marginBottom: 0
                                        }}>Not Acceptable Location Message</label>
                                        <br/>
                                        <RichTextEditor toolbarConfig={toolbarConfig} id="notAcceptableLocationMessage"
                                                        name="notAcceptableLocationMessage"
                                                        value={notAcceptableLocationMessage}
                                                        onChange={(text) => this.handleRichTextChange( 'notAcceptableLocationMessage', text)}
                                                        placeholder="Not Acceptable Location Message"/>
                                        <label htmlFor="acceptableDistance" style={{marginTop: 5, marginBottom: 0}}>Distance (Miles)</label> <input
                                        type="checkbox" onClick={() => this.setState({canPlayOutside: false})}
                                        checked={!canPlayOutside}/> Fans Can Play Within <input
                                        onClick={() => this.setState({canPlayOutside: true})} type="checkbox"
                                        checked={canPlayOutside}/> Fans Can Play Outside
                                        <br/>
                                        <input className="form-control" type="number" id="acceptableDistance" step="0.1"
                                               name="acceptableDistance" min="0" value={acceptableDistance}
                                               onChange={this.handleChange} placeholder=""/>
                                    </div>
                                    <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}}
                                            onClick={() => this.updateDistance()}><span
                                        className="fa fa-arrow-circle-o-up"/> Update Distance Variables</button>
                                </span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="doNotCollectEmail">DO NOT Collect Email</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to remove collecting of email. ONLY CHECK THIS IF YOU KNOW WHAT YOU ARE DOING</p>
                            <input type="checkbox" checked={doNotCollectEmail} id="doNotCollectEmail" name="doNotCollectEmail" onClick={()=> this.selectOption("doNotCollectEmail")}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="noMandatoryTermsAndConditions">Turn OFF Mandatory Terms And Conditions</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to remove the MANDATORY confirm rules and regs checkbox<br/>Rules and Regs Text and Link come from the <a href="/setuprulesandregs"><u>Rules and Regs Tab</u></a></p>
                            <input type="checkbox" checked={noMandatoryTermsAndConditions} id="noMandatoryTermsAndConditions" name="noMandatoryTermsAndConditions" onClick={()=> this.selectOption("noMandatoryTermsAndConditions")}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="sanitizeEmails">Sanitize Emails</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to map accounts with the same sanitized email to the same account<br/>(could lead to issues with users having the same email address)</p>
                            <input type="checkbox" checked={sanitizeEmails} id="sanitizeEmails" name="sanitizeEmails" onClick={()=> this.selectOption("sanitizeEmails")}/>
                        </div>
                        {collectZipCode &&
                            <div className="form-group">
                                <label htmlFor="doNotVerifyAmericanZipCode">Do Not Verify American Zip Code?</label>
                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Do not verify American Zip Code. Check this only if the zip codes you are collecting are outside the U.S.</p>
                                <input type="checkbox" checked={doNotVerifyAmericanZipCode} id="doNotVerifyAmericanZipCode" name="doNotVerifyAmericanZipCode" onClick={()=> this.selectOption("doNotVerifyAmericanZipCode")}/>
                            </div>
                        }
                        {collectZipCode && doNotVerifyAmericanZipCode &&
                            <div className="form-group">
                                <label htmlFor="verifyCanadianZipCode">Verify Canadian Zip Code?</label>
                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Verify Canadian Zip Code. Check this only if the zip codes you are collecting are from Canada</p>
                                <input type="checkbox" checked={verifyCanadianZipCode} id="verifyCanadianZipCode" name="verifyCanadianZipCode" onClick={()=> this.selectOption("verifyCanadianZipCode")}/>
                            </div>
                        }
                        <div className="form-group">
                            <label htmlFor="blockList">Block List Emails/Domains</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter any emails or domains that should be blocked from logging in.</p>
                            <textarea className="form-control" id="blockList" name="blockList" value={this.state.blockList} onChange={this.handleChange}/>
                            <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateList('block')}><span className="fa fa-arrow-circle-o-up"/> Update Block List</button>
                        </div>
                        <div className="form-group">
                            <label htmlFor="allowList">Allow List Emails/Domains</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter any emails or domains that should be allowed to login, all other emails/domains will be blocked. This will SUPERSEDE any emails/domains on the block list and let them play</p>
                            <textarea className="form-control" id="allowList" name="allowList" value={this.state.allowList} onChange={this.handleChange}/>
                            <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateList('allow')}><span className="fa fa-arrow-circle-o-up"/> Update Allow List</button>
                        </div>
                    </>
                    }
                </div>
              </div>
              <div className="container-out mobile-hide" style={{width:'45%', float:'right', marginRight:'20px'}}>
                <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>What Data Should I Gather?</p>
                  <p className="text-muted2">Think carefully about what you want to gather from your fan base.  The more you ask for, the fewer people will be willing to go through the whole logging in process.
                  Balance that with useful parameters that you can use to help your fans get more value from your experience.</p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpLoginVariables;
